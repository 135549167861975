import React from "react";

function Footer() {
  return (
    <>
      <footer className="d-flex flex-column align-items-center">
        <div className="foot-links d-flex flex-sm-row flex-column my-5 text-center">
          <p>Home</p>
          <p>Services</p>
          <p>About</p>
          <p>Why Choose Us</p>
          <p>Testimonials</p>
          <p>Contact Us</p>
        </div>
        <div className="logo mb-5">
            <h1>Call Us: 07533629850</h1>
        </div>
      </footer>
    </>
  );
}

export default Footer;